/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {



  // (function (h, i, v, e, s, d, k) {
  //   h.HiveSDKObject = s;
  //   h[s] = h[s] || function () {
  //     (h[s].q = h[s].q || []).push(arguments)
  //   }, d = i.createElement(v), k = i.getElementsByTagName(v)[0];
  //   d.async = 1;
  //   d.id = s;
  //   d.src = e + '?r=' + parseInt(new Date() / 60000);
  //   k.parentNode.insertBefore(d, k)
  // })(window, document, 'script', 'https://cdn-prod.hive.co/static/js/sdk-loader.js', 'HIVE_SDK')

  // HIVE_SDK('init', 133219, function (data) { // Initialization success callback
  //   // data.user contains info about the currently user (if available)
  //   console.log(data)
  // });


}